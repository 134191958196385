<template>
  <Content :route="'/usuarios'" @event="novo" :action="action" title="Integrações">
    <!--  <template #selects>
      <LoadingButton :isLoading="loadingIntegracao" :button-class="'btn-integrar'" @event="integrar">
        Integrar
      </LoadingButton>
    </template> -->
    <Interface noBtn :noTitle="true"
      :sortOptions="[{ value: 'key', text: 'Cliente' }, { value: 'type', text: 'Empresa' }, { value: 'description', text: 'Sistema' }]"
      @sort="sort" @filter="filterSwitch">
      <template #integrar>
        <LoadingButton :isLoading="loadingIntegracao" :button-class="'btn-integrar'" @event="integrar(true)">
          <img src="../assets/images/cloud.png" alt=""> Incremental
        </LoadingButton>
        <LoadingButton v-if="!loadingIntegracao" :isLoading="loadingIntegracao" :button-class="'btn-integrar'"
          @event="integrar(false)">
          <img src="../assets/images/cloud.png" alt=""> Total
        </LoadingButton>
      </template>
      <Table ref="table" :updatingStatus="updatingStatus" @updateIntegracaoEvent="showModalEditIntegracao"
        @datalakeStatus="datalakeStatus" @update="updateIntegracao" :is-filtering="isFiltering"
        :filter-data="filterData" :isLoading="isLoading" :module="routeModule" :items="itemsTable" :fields="fields"
        :edit="edit" :sortBy="sortBy" :sortDirection="sortDirection" checkboxs></Table>
    </Interface>

    <b-modal size="lg" centered id="modal-2">
      <!--       <template #modal-header> -->
      <b-row class="mb-1 text-center" style="width: 100%; margin: auto;">
        <b-col cols="6">
          <h2 class="title">Agendar atualização</h2>
        </b-col>
        <b-col cols="6">
          <b-button type="reset" variant="light" @click="$refs.ai.clear()">Limpar</b-button>
          <b-button type="submit" variant="dark" @click="$refs.ai.save()">Salvar</b-button>
        </b-col>
      </b-row>
      <!--       </template> -->
      <b-container fluid>
        <AgendarIntegracao ref="ai" @salvarAgendamento="salvarFormulario" :selectedIntegracao="selectedIntegracao"
          :empresas="company" :clientes="users" :sistemas="sistemas"></AgendarIntegracao>
      </b-container>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal size="lg" centered title="Status" id="modal-status">
      <div>
        <template v-if="errorStatus.length">
          <div v-for="e in errorStatus" :key="'error-' + e.id">
            <h3>#ID {{ e.id }}</h3>
            <b-container class="bv-example-row">
              <b-row class="text-left">
                <b-col cols="3" class="font-weight-bold">Código:</b-col>
                <b-col cols="9">{{ e.code }}</b-col>
              </b-row>
              <b-row class="text-left">
                <b-col cols="3" class="font-weight-bold">Nome da Tabela:</b-col>
                <b-col cols="9">{{ e.table_name }}</b-col>
              </b-row>
              <b-row class="text-left">
                <b-col cols="3" class="font-weight-bold">Usuário ID:</b-col>
                <b-col cols="9">{{ e.user_id }}</b-col>
              </b-row>
              <b-row class="text-left">
                <b-col cols="3" class="font-weight-bold">Criado em:</b-col>
                <b-col cols="9">{{ dateFormat(e.created_at) }}</b-col>
              </b-row>
              <b-row class="text-left">
                <b-col cols="3" class="font-weight-bold">Atualizado em:</b-col>
                <b-col cols="9">{{ dateFormat(e.updated_at) }}</b-col>
              </b-row>
              <b-row class="text-left">
                <b-col cols="12" class="font-weight-bold">Mensagem:</b-col>
                <b-col cols="12">
                  <b-form-textarea :value="e.message" rows="5"></b-form-textarea>
                </b-col>
              </b-row>
            </b-container>
            <hr />
          </div>
          <b-button v-if="page < totalPages" variant="primary" @click="datalakeStatus()">Ver mais</b-button>
        </template>
        <template v-else>
          <span v-if="!loadingStatus">Nenhuma solicitação foi encontrada.</span>
        </template>
        <template v-if="loadingStatus">
          <div class="text-center">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </template>
      </div>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
  </Content>
</template>

<script>
/* eslint-disable */
import Interface from '@/components/Dashboard/Interface'
import Content from '../components/content/index'
import Table from '../components/Dashboard/Table.vue'
import { dateFormat, toast } from '../utils.js'
import AgendarIntegracao from '@/components/Modals/AgendarIntegracao.vue'
import { request } from '@/services'
import LoadingButton from '@/components/Shared/LoadingButton.vue'
export default {
  components: {
    Interface, Content, Table, AgendarIntegracao, LoadingButton
  },
  data: () => ({
    sortBy: undefined,
    sortDirection: true,
    isFiltering: false,
    filterData: {},
    edit: null,
    updatingStatus: {},
    fields: [
      { key: 'selected', label: ' ' },
      { key: 'Habilitado', label: 'Habilitado' },
      { key: 'user_id', label: 'Cliente' },
      { key: 'company_id', label: 'Empresa' },
      { key: 'description', label: 'Módulo' },
      { key: 'updated_at', label: 'Atualização' },
      { key: 'schedule', label: 'Agendamento' },
      // { key: 'Integrar', label: 'Integrar' },
      { key: 'EditarIntegracao', label: 'Editar' },
      { key: 'Excluir', label: 'Excluir' },
      { key: 'DatalakeStatus', label: 'Status' }
    ],
    bodyOptions: {
      mode: { name: 'JSON', json: true },
      smartIndent: true,
      indentWithTabs: true,
      readOnly: true,
      lineWrapping: true
    },
    items: [],
    users: [],
    company: [],
    sistemas: [],
    isLoading: true,
    errorStatus: [],
    loadingStatus: true,
    step: 10,
    page: 0,
    totalPages: 1,
    // loadingIntegracao: false,
    selectedIntegracao: null,
    lastItem: null
  }),
  watch: {
    filterData: {
      handler(value) {
        Object.keys(value).forEach((key) => {
          if (value[key] === '') {
            // console.log("empty: " + value);
            delete value[key]
          }
        })
      },
      deep: true
    }
  },
  computed: {
    itemsTable() {
      return this.items
        .map(a => ({ ...a, user_id: this.users.find(b => b.id === a.user_id)?.name }))
        .map(a => ({ ...a, company_id: this.company.find(c => c.id === a.company_id)?.name }))
        .map(a => ({ ...a, description: this.sistemas.find(b => b.id === a.group_id)?.key }))
        .map(a => ({ ...a, schedule: a.periodicity ? 'cada ' + a.periodicity + ' minutos' : (Array.isArray(a.hour) ? a.hour[0] : a.hour) }))
        .map(a => { return { ...a, checked: a.active === 1 } })
    },
    action() {
      return { options: { label: 'Novo', icon: 'add' } }
    },
    /*
    items () {
      if (this.$store.state.sistemas.items.length !== 0) {
        return Object.entries(this.$store.state.sistemas.items.groups).map(a => { return { ...a[1], checked: false } }).map(a => ({ ...a, updated_at: dateFormat(a.updated_at) }))
      } else {
        return this.$store.state.sistemas.items
      }
    },
    */
    routeModule() {
      return this.$store.state.integracoes
    },
    loadingIntegracao() {
      return Object.values(this.updatingStatus).reduce((v, s) => v || s === 'loading', false)
    }
  },
  created() {
    this.$store.dispatch('integracoes/init', { self: this })
    this.$store.dispatch('sistemas/init', { self: this })
    this.$store.dispatch('clientes/init', { self: this })
    this.$store.dispatch('empresas/init', { self: this })
    request.get('/integracao/index', res => {
      this.items = res.data.updates.map(a => ({ ...a, updated_at: dateFormat(a.updated_at) }))
      this.isLoading = false
    }, e => toast(this, 'danger', 'Erro', e))

    request.get('user/index?group_key="Client"', res => {
      this.users = res.data.users
    }, e => toast(this, 'danger', 'Erro', e))

    request.get('user/index?group_key="Client_CNPJ"', res => {
      this.company = res.data.users
    }, e => toast(this, 'danger', 'Erro', e))

    request.get('group/index', res => {
      this.sistemas = Object.values(res.data.groups.filter(a => a.type === 'Module') || {})
    }, e => toast(this, 'danger', 'Erro', e))
  },
  methods: {
    showModalEditIntegracao(item) {
      this.selectedIntegracao = item
      this.$bvModal.show('modal-2')
    },
    datalakeStatus(item) {
      if (item) {
        this.errorStatus = []
        this.page = 0
      }
      // const uId = this.users.find(b => b.name === item.user_id)?.id
      this.lastItem = item || this.lastItem
      // const uId = this.company.find(b => b.name === this.lastItem.company_id)?.id

      // if (!uId) {
      //   toast(this, 'info', 'Por favor aguarde os dados de usuário carregarem', 'ID do usuário não encontrado. Por favor espere alguns segundos e tente denovo')
      //   return
      // }
      this.$bvModal.show('modal-status')
      this.loadingStatus = true
      request.get('https://api.hub-dinamic.businessindicator.com/api/datalakeerrors/show/' + this.lastItem.id + '?page=' + (this.page + 1), res => {
        this.errorStatus = [...(this.errorStatus || []), ...(res.data?.itens?.data || [])]
        this.totalPages = res.data.total_pages
        this.page = res.data?.itens?.current_page
        this.loadingStatus = false
      }, e => {
        this.loadingStatus = false
        this.$bvModal.hide('modal-status')
        setTimeout(() => toast(this, 'danger', 'Erro', e), 100)
      })
    },
    novo() {
      this.selectedIntegracao = null
      this.$bvModal.show('modal-2')
    },
    integrar(incremental) {
      if (!this.$refs.table.selected.length) return toast(this, 'info', 'Nenhuma integração selecionada', 'Por favor selecione pelo menos uma integração para continuar')
      this.$refs.table.selected.forEach(a => {
        // console.log(a)
        const uId = this.company.find(b => b.name === a.company_id)?.id
        if (!uId) {
          toast(this, 'info', 'Por favor aguarde os dados de usuário carregarem', 'ID do usuário não encontrado. Por favor espere alguns segundos e tente denovo')
          this.$refs.table.selected = []
          return
        }
        // this.loadingIntegracao = true
        this.updatingStatus = { ...this.updatingStatus, [a.id]: 'loading' }
        request.post('integracao/updateAll', {
          user_id: uId,
          module: a.description,
          integracao_id: a.id,
          incremental
        }, res => {
          // this.loadingIntegracao = false
          this.updatingStatus = { ...this.updatingStatus, [a.id]: 'finished' }
          // console.log(res.data)
          if (!this.loadingIntegracao) toast(this, 'success', 'Status', 'Integrações atualizadas')
        }, e => {
          toast(this, 'danger', 'Erro', e)
          this.updatingStatus = { ...this.updatingStatus, [a.id]: 'error' }
        })
      })
    },
    updateIntegracao(e) {
      request.post('datalake/update/' + e.item.user_id, {}, res => {
        console.log(res.data)
        toast(this, 'success', 'Status', 'Datalake atualizado')
      }, e => toast(this, 'danger', 'Erro', e))
    },
    salvarFormulario(id, form) {
      const agendamento = {
        frequency: false,
        periodicity: form.periodicidade,
        active: true,
        start: form.start,
        end: form.end,
        hour: form.hora,
        group_id: form.sistema,
        user_id: form.cliente,
        company_id: form.empresa
      }
      const m = id ? request.put : request.post
      m('integracao/' + (id ? ('update/' + id) : 'store'), agendamento,
        res => {
          const v = { ...res.data.update, active: +res.data.update.active, updated_at: dateFormat(res.data.update.updated_at) }
          this.items = id ? this.items.map(i => i.id === v.id ? v : i) : [...this.items, v]
          this.$bvModal.hide('modal-2')
          setTimeout(() => toast(this, 'success', 'Integração', 'Integração criada com sucesso'), 500)
        },
        (error) => {
          toast(this, 'danger', 'Registro inválido', error)
          return Promise.reject(error)
        })
    },
    filterSwitch(value) {
      this.isFiltering = value
      if (!value) this.filterData = {}
    },
    onEdit(id) {
      this.edit = this.edit !== id ? id : null
    },
    onSave(item, id) {
      this.edit = this.edit !== item ? item : null

      for (const [key, value] of Object.entries(item)) {
        item[key] = !value ? null : value
      }

      let form = item

      form = Object.assign({}, form, {
        // group_id: form.groups[0].id,
        user_id: form.id
      })

      // eslint-disable-next-line no-unexpected-multiline
      [
        // eslint-disable-next-line no-sequences
        'remember_token',
        'tenant_id',
        'deleted_at',
        'updated_at',
        'created_at'
      ].forEach((e) => delete form[e])

      console.log(form)
    },
    sort(e, z) {
      this.sortBy = e
      this.sortDirection = z
    },
    dateFormat() {
      return dateFormat(...arguments)
    }
  }
}
</script>
<style scoped lang="stylus">
@import '../style/colors';
@import '../style/fonts.styl';
@import '../style/mixins.styl';

.btn-integrar{
    /* position: absolute;
    left: calc(100% - 15vw); */
    background-color: #0de;
    width: -moz-fit-content;
    width: fit-content;
   /*  top: calc(100% - 75vh); */
    border-color: #0de;
}

.table {
  color: greyLight !important;
  border-collapse: separate;
  border-spacing: 0 1em;
}

.table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default {
  background-color: light !important;
  color: black;
}

.table td, .table th, .table thead th {
  border: none;
  white-space: nowrap;
}

.table thead th {
  padding: 0 0.75rem;
  font-size: 20px;
}

.table tr {
  // background-color content-base-theme()
  background-color: primary;
  transition: 0.4s;

  &:focus {
    outline: none;
  }
}

radius = 10px;

.table tr td:first-child {
  border-top-left-radius: radius;
  border-bottom-left-radius: radius;
  width: 8%;
}

.table tr td:last-child {
  border-top-right-radius: radius;
  border-bottom-right-radius: radius;
}

.table th div {
  nowrap();
}

.table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td {
  background-color: secondary !important;
}

.table.b-table tr {
  .btn-actions {
    min-width: 64px;
    display: inline;
  }

  i {
    opacity: 0;
    transition: 0.3s;
    background-color: green;
  }

  .loading-btn {
    opacity: 0;
    transition: 0.3s;
    position relative

    span {
      position absolute
      right 10px
      bottom -1px
    }
  }

  &:hover {
    i {
      opacity: 1;
    }

    .loading-btn {
      opacity: 1;
    }
  }
}

.ec {
  align-self: flex-end;
}

.btn {
  justify-content: space-between;
  margin-left: 10px;
  float: left;
}

.btn-service {
  display: inline;
  margin-left: 0px;
}

.checkbox, .radio {
  display: block;
  width: 25px;
  height: 25px;
  background-color: light;
  // padding 20px
  border: 1px solid black;
  cursor: pointer;
  margin-top: 4px;

  .fill-selected {
    min-height: 100%;
    // border-radius: 4px
    transition: 0.2s;
    transform: scale(0.2);
  }

  .active {
    background-color: green;
    width: auto;
    height: auto;
    transform: scale(1);
  }
}

.radio {
  &, .fill-selected {
    border-radius: 50%;
  }
}

#remessa___BV_modal_header_, #remessa___BV_modal_footer_ {
  display: none;
}

#remessa___BV_modal_content_ {
  background-color: light;
  border-radius: 8px;
}

.title-btn-2 {
  background-color: blue;
  color: white;
  border-radius: 5px;
  padding: 6px 30px;
  cursor: pointer;
  margin-top: -4.5rem;
  position: absolute;
  top: -166px;
  right: 179px;
  font-family: Calibri;
  height: 38px;
  display: flex;
  align-items: center;

  .spinner-border {
    width: 1.25rem !important;
    height: 1.25rem !important;
  }

  &:hover {
    background-color: alpha(primary, 0.8);
    transition: 0.2s;
  }

  span, .icon-btn {
    vertical-align: middle;
    no-select();
  }
}

.btn-icon
    border-radius: 50%
    background-color primary
    color white
    padding 5px
    cursor pointer
    no-select()
    transition .3s
    margin 0 8px

    &:hover
        background-color primary

.btn-icons
    display flex
    justify-content center

    .title
        padding 0px 10px
        font-size 14px
        color light
        font-weight bold
        border-radius 1rem
        background primary

    span
        align-self center

    span:last-child
        border 1px dashed primary
        height 1px

    span:last-child
        flex-grow 1
.btn
  justify-content space-between
  margin-left 10px
  float left

.btn-service
  float left
  margin-left 1px
  border-radius 50%
  margin-top 20px

.service
  width 50%
  float right

.btn-icon-red
    background-color red !important

.table
    color greyLight !important
    border-collapse separate
    border-spacing 0 1em

.table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default
    background-color light !important
    color black

.table td, .table th, .table thead th
    border none
    white-space nowrap

.table thead th
    padding 0 .75rem
    font-size 20px

.table tr
    //background-color content-base-theme()
    background-color primary
    transition .4s

radius = 10px

.table tr td:first-child
    border-top-left-radius radius
    border-bottom-left-radius radius
    width 8%

.table tr td:last-child
    border-top-right-radius radius
    border-bottom-right-radius radius

.table th div
    nowrap()

.table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td
    background-color secondary !important

.table.b-table tr
    .btn-actions
        min-width 64px
    i
        opacity 0
        transition .3s
        background-color green

    &:hover
        i
            opacity 1

</style>
