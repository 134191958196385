<template>
    <div>
      <b-form ref="form">
        <!--
        <b-row style="width: 100%">
            <b-col cols="6">
            <b-form-group label="Frequência:" label-for="frequencia">
          <b-form-select v-model="form.frequencia" id="frequencia">
            <option v-for="option in form.frequenciaOptions" :value="option" :key="option">{{ option }}</option>
          </b-form-select>
        </b-form-group>
        </b-col>
        </b-row>
        -->
        <b-row>
            <b-col cols="6">
            <b-form-group label="Cliente:" label-for="sistema">
          <b-form-select v-model="form.cliente" id="sistema" :options="clientesOptions"></b-form-select>
        </b-form-group>
        </b-col>
        </b-row>
        <b-row>
            <b-col cols="6">
            <b-form-group label="Empresa:" label-for="sistema">
          <b-form-select v-model="form.empresa" id="sistema" :options="empresasOptions"></b-form-select>
        </b-form-group>
        </b-col>
        </b-row>

        <b-row>
            <b-col cols="6">
            <b-form-group label="Módulo:" label-for="sistema">
          <b-form-select v-model="form.sistema" id="sistema" :options="sistemasOptions"></b-form-select>
        </b-form-group>
        </b-col>
        </b-row>

        <b-row>
            <b-col cols="6">
            <b-form-group label="Periodicidade:" label-for="periodicidade">
          <b-form-select v-model="form.periodicidade" id="periodicidade" :options="[{ value: null, text: 'Nenhuma. Usar hora fixa' }, { value: 30, text: '30 Minutos' }, { value: 60, text: '1 Hora' }, { value: 120, text: '2 Horas' }, { value: 240, text: '4 Horas' }, { value: 480, text: '8 Horas' }]"></b-form-select>
        </b-form-group>
        </b-col>
        </b-row>
        <!--
        <b-col v-if="form.frequencia === 'Semanal'" cols="4">
          <label v-for="(dia, index) in form.diasSemana" :key="index">
            <input type="checkbox" :value="index" v-model="form.diasSelecionados">{{ dia }}
          </label>
       </b-col>
       -->

       <b-row v-if="!form.periodicidade">
        <b-col cols="6">
        <b-form-group label="Hora Fixa:" label-for="hora">
          <b-form-input v-model="form.hora" type="time" id="hora" step="1800"></b-form-input>
        </b-form-group>
       </b-col>
       </b-row>
      </b-form>
    </div>
  </template>
<script>
export default {
  data: () => ({
    form: {}
  }),
  props: {
    sistemas: {
      type: Array,
      default: () => ([])
    },
    empresas: {
      type: Array,
      default: () => ([])
    },
    clientes: {
      type: Array,
      default: () => ([])
    },
    selectedIntegracao: {
      type: Object,
      required: false
    }
  },
  /*
  watch: {
    diasSelecionados () {
      this.$emit('update-dias', this.diasSelecionados)
    }
  },
  */
  computed: {
    sistemasOptions () {
      return this.sistemas.map(a => ({ value: a.id, text: a.key }))
    },
    clientesOptions () {
      return this.clientes.map(a => ({ value: a.id, text: a.name }))
    },
    empresasOptions () {
      return this.empresas.filter(e => !this.form?.cliente || e.tenant_id === this.form?.cliente).map(a => ({ value: a.id, text: a.name }))
    }
  },
  created () {
    if (!this.selectedIntegracao) return

    this.form = {
      cliente: this.clientes.find(a => a.name === this.selectedIntegracao.user_id)?.id,
      empresa: this.empresas.find(a => a.name === this.selectedIntegracao.company_id)?.id,
      sistema: this.sistemas.find(a => a.key === this.selectedIntegracao.description)?.id,
      periodicidade: this.selectedIntegracao.periodicity || null,
      hora: this.selectedIntegracao.hour
    }
  },
  methods: {
    save () {
      this.$emit('salvarAgendamento', this.selectedIntegracao?.id, this.form)
    },
    clear () {
      this.form = {}
    }
  }
}
</script>
